import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import ReactPlayer from "react-player";
import * as css from "./Video.module.css";

const youtubeConfig = ({ loop, autoPlay }) => ({
  playerVars: {
    controls: 0,
    autoplay: autoPlay ? 1 : 0,
    loop: loop ? 1 : 0,
    modestbranding: 1,
    rel: 0,
    playsinline: 1,
  },
});

const vimeoConfig = ({ loop, autoPlay }) => ({
  playerOptions: {
    autoplay: autoPlay,
    autopause: false,
    loop,
    controls: false,
    portrait: false,
    title: false,
  },
});

const PlayIcon = () => (
  <div className={css.playIconWrapper}>
    <div className={css.playIcon} />
  </div>
);

export const Video = ({
  url,
  poster,
  loop = true,
  autoplay = true,
  noLink,
  className,
  ...otherProps
}) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsPlaying(true);
        } else {
          setIsPlaying(false);
        }
      },
      { threshold: 0.5 } // Se activa cuando el 50% del video es visible
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div
      ref={videoRef}
      className={classnames(css.root, className, { [css.noLink]: noLink })}
      {...otherProps}
    >
      <div className={css.container}>
        <ReactPlayer
          muted={true} // Necesario para autoplay en navegadores modernos
          loop={loop}
          playsinline={true}
          className={css.reactPlayer}
          url={url}
          playing={isPlaying}
          config={{
            youtube: youtubeConfig({ loop: loop, autoPlay: isPlaying }),
            vimeo: vimeoConfig({ loop: loop, autoPlay: isPlaying }),
          }}
        />
      </div>
    </div>
  );
};

export default Video;